@import "./app/private/social/messages/components/tolaria-emoji-picker/tolaria-emoji.global.style.css";
@import "./app/private/social/messages/components/tolaria-wysiwyg/tolaria-wysiwyg.component.css";

@import "./assets/css/variables.css";
@import "./assets/css/mtg-set-icons.css";
@import "./assets/css/loaders.css";
@import "./assets/css/bootstrap-adjustments.css";
@import "./assets/css/text-styling.css";
@import "./assets/css/card-frames.css";
@import "./assets/css/card-mana-symbols.css";
@import "./styles.mobile.css";
@import "./assets/css/ngb-adjustments.css";

/* FIX for conflicting classes https://mana.andrewgioia.com/index.html */
.ms-0:not(.ms)::before,
.ms-1:not(.ms)::before,
.ms-2:not(.ms)::before,
.ms-3:not(.ms)::before,
.ms-4:not(.ms)::before,
.ms-5:not(.ms)::before {
  display: none;
}

.event-info-tooltip {
  min-width: var(--event-tooltip-width);
  max-width: var(--event-tooltip-width);
  min-height: 30rem !important;
  text-align: left !important;
  background-color: var(--content-bg) !important;
  margin: 0 0.5rem;
}

.event-info-tooltip .arrow {
  display: none !important;
}

.event-info-tooltip .tooltip-inner {
  max-width: fit-content !important;
  padding: 0 !important;
  color: var(--content-text) !important;
  text-align: left !important;
  border-radius: 0 !important;
  margin: 0 -0.5rem !important;
  opacity: 1 !important;
}

.event-info-tooltip.show {
  opacity: 1 !important;
}

.sticky {
  position: sticky;
  top: 0;
}

.btn-ml-neg {
  margin-left: -0.6rem;
}

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "MagicMedieval";
  src: url("~/src/assets/fonts/magmed.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "MPlantin";
  src: url("~/src/assets/fonts/mplantin.ttf") format("truetype");
}

@font-face {
  font-family: "SmokingParadise";
  src: url("../src/assets/fonts/SmokingParadise.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-weight: bold;
}

@font-face {
  font-family: "Sinister Sam";
  src: url("../src/assets/fonts/SSAMTRIAL.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-weight: bold;
}

@font-face {
  font-family: "Saucy Jack";
  src: url("../src/assets/fonts/SaucyJack.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-weight: bold;
}

@font-face {
  font-family: "Sinthya";
  src: url("../src/assets/fonts/Sinthya.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-weight: bold;
}

@font-face {
  font-family: "Tomatoes";
  src: url("../src/assets/fonts/Tomatoes-O8L8.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-weight: bold;
}

@font-face {
  font-family: "PressStart2P";
  src: url("../src/assets/fonts/PressStart2P-Regular.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-weight: bold;
}

html,
body {
  height: 100%;
  outline: none;
  font-size: var(--font-normal);
  background: black;
  background-image: url("assets/tolaria-landing-page.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  overflow: hidden;
}
body.printing {
  background: white !important;
  overflow: hidden auto;
  display: block;
  position: relative;
  height: auto !important;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

input:not(.form-range),
textarea,
select {
  font-size: var(--font-normal) !important;
  background-color: var(--main-bg) !important;
  border-radius: 0.25rem;
  /* border: 1px solid var(--content-inner-border) !important; */
  border: 1px solid #6c757d !important;
  color: var(--content-text) !important;
}

input:disabled,
textarea:disabled,
select:disabled {
  border-color: #53595e !important;
  color: #6c757d !important;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: opacity(0.25) invert(1);
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator:hover {
  filter: opacity(1) invert(1);
  cursor: pointer;
}

.transition-all-25 {
  transition: all 0.25s;
}

.rotate-90deg {
  transform: rotate(90deg);
  transition: all 0.25s;
}

.rotate-normal-90deg {
  transform: rotate(90deg);
  transition: all 0.25s;
}

.rotate-normal-180deg {
  transform: rotate(180deg);
  transition: all 0.25s;
}

.rotate {
  transition: all 0.25s;
}

.rotate-20deg {
  transform: rotate(20deg);
  -webkit-transform: rotate(20deg);
  /* Safari and Chrome */
  -moz-transform: rotate(20deg);
  /* Firefox */
}

.rotate-35deg {
  transform: rotate(35deg);
  -webkit-transform: rotate(35deg);
  /* Safari and Chrome */
  -moz-transform: rotate(35deg);
  /* Firefox */
}

.rotate-180deg {
  transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateX(180deg);
  /* Firefox */
}

.flipped-180deg {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}

.inline {
  display: inline-block !important;
}

:focus {
  outline: none;
}

button {
  outline: none;
}

button.close {
  outline: none;
}

button.btn-no-fill:hover {
  background-color: lightgray;
}

a.router-link {
  text-decoration: none;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

pre {
  color: var(--content-text);
}

.full-height {
  height: 100%;
}

app-event-room,
app-dashboard,
app-down-to-play,
app-events,
app-event,
app-tournament,
app-players,
app-collection,
app-messages,
app-admin,
app-cards {
  position: absolute;
  top: var(--top-bar-height);
  left: var(--left-bar-width);
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: var(--main-bg);
  color: var(--content-text);
}

app-dashboard.full-size {
  left: 0;
}

app-event-room {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

app-message-toast {
  width: 100%;
  height: 2rem;
  position: absolute;
  bottom: 0;
  text-align: center;
}

svg.logo {
  width: calc(var(--left-bar-width) * 0.6667);
}

.main {
  font-size: var(--font-normal);
  padding: 1rem;
  position: absolute;
  top: var(--top-bar-height);
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.main.image-back {
  backdrop-filter: blur(5px);
}

.parchment img {
  width: 100%;
}

.parchment {
  padding: 5rem;
}

.tolaria {
  font-family: "SmokingParadise";
}

.tolaria.h1 {
  font-size: var(--font-mega);
}

.card-inline {
  display: inline-block;
}

.card {
  background-color: var(--content-bg);
  border: var(--top-bar-border) 1px solid;
  border-radius: 0;
  color: var(--content-text);
  min-width: 20rem;
  max-width: 20rem;
}

.card-header {
  font-size: var(--font-large);
}

.card-large {
  min-width: 25rem;
  max-width: 30rem;
  max-height: 100%;
}

.card-xlarge {
  min-width: 35rem;
  max-width: 40rem;
  max-height: 100%;
}

.card-xxlarge {
  min-width: 45rem;
  max-width: 50rem;
  max-height: 100%;
}

.card-xxxlarge {
  min-width: 50rem;
  max-width: 60rem;
  max-height: 100%;
}

.card.card-auto {
  min-width: 20rem;
  max-width: 100vw;
}

.card:hover {
  border-color: rgba(var(--bs-secondary-rgb), 0.75);
  border-radius: 0.325rem;
}

.card-subtitle {
  font-size: var(--font-normal);
  padding: 0.25rem 0;
}

.btn-read-more {
  color: var(--content-text);
}

.card-body.scrollable {
  overflow: auto;
}

.card-body:hover,
.btn-read-more:hover {
  color: var(--content-hover-title-text);
  cursor: pointer;
}

.card.no-hover {
  background-color: var(--content-bg);
}

.card.no-hover:hover,
.card-body.no-hover:hover {
  cursor: default;
  color: var(--content-text);
}

.btn-main {
  color: var(--content-text) !important;
  background-color: var(--bg-orange) !important;
  border-color: var(--bg-orange) !important;
}

.btn-main:hover {
  color: var(--content-text);
  background-color: var(--bg-dark-orange) !important;
  border-color: var(--bg-dark-orange) !important;
}

.btn-outline-main {
  color: var(--bg-orange) !important;
  border-color: var(--bg-orange) !important;
}

.btn-outline-main:hover {
  color: black !important;
  border-color: var(--bg-orange) !important;
  background-color: var(--bg-orange) !important;
}

.btn-wrapper {
  color: inherit;
  padding-left: 0;
  padding-right: 0;
  cursor: default !important;
}

.btn-wrapper:hover {
  text-decoration: none;
  color: inherit;
  cursor: default !important;
}

/* .btn-close {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0.25rem;
  text-align: center;
  transition: all 0.35s;
  cursor: pointer;
}

.btn-close:hover,
.btn-close.active {
  background-color: var(--content-hover-title-text);
  color: black !important;
} */

@media (min-width: 576px) {
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 1px solid var(--top-bar-border);
  }
}

.modal.show {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2.5px);
  display: block;
}

.modal-content {
  background-color: var(--content-bg);
  color: var(--content-text);
  border: 1px solid var(--content-outer-border);
}

.modal-body.settings-body,
.modal-body.settings-body .btn.btn-nav-menu {
  color: var(--content-text);
}

.modal-body.settings-body .btn.btn-nav-menu:hover {
  background-color: var(--card-wrapper-box-shadow);
}

.modal-header {
  border-bottom: 1px solid var(--content-inner-border);
}

.modal-footer {
  border-top: 1px solid var(--content-inner-border);
}

.modal-dialog-wrapper .card {
  background-color: var(--modal-dialog-bg);
  border: 1px solid var(--modal-dialog-border);
}

/* LOADER */
.isBusy {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20000;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1.5px);
}

.loader-wrapper {
  min-width: 10rem;
  max-width: 20rem;
  height: auto;
  left: calc(50% - 5rem);
  margin-right: auto;
  top: calc(50% - 10rem);
  background-color: black;
  border-radius: 0.5rem;
  position: absolute;
  padding: 2rem;
  text-align: center;
  color: whitesmoke;
  font-size: var(--font-normal);
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.col-1-5 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.btn-blank {
  color: var(--content-text);
  border-color: rgba(0, 0, 0, 0) !important;
}

.btn-blank:hover {
  color: var(--content-hover-title-text) !important;
}

/* ngx-sortable custom css */
.new-playoff-config div.sortable-container {
  border: none !important;
}

.new-playoff-config .sortable-container ul li:hover,
.new-playoff-config .sortable-container ul li.active:hover {
  cursor: grab !important;
}

.new-playoff-config .sortable-container .active {
  background-color: transparent !important;
}

.new-playoff-config .sortable-container ul li:active {
  cursor: grabbing !important;
}

.new-playoff-config .sortable-container ul li.active:active {
  cursor: grabbing !important;
}

.new-playoff-config .sortable-container ul li .drop-zone {
  border: none !important;
  background-color: rgba(255, 255, 255, 0.125) !important;
  border-radius: 0.25rem;
  height: 3rem;
  min-height: 3rem;
  margin-bottom: 0.5rem;
}

/*
ngx-sortable div.sortable-container {
  border: none !important;
}
.card-body ngx-sortable div.sortable-container {
  border: none !important;
}
.card-body ngx-sortable div.sortable-container ul li.active,
.card-body ngx-sortable div.sortable-container ul li:hover {
  background-color: var(--search-bar-bg) !important;
  color: var(--content-hover-title-text) !important;
}
.card-body ngx-sortable div.sortable-container ul li .drop-zone {
  border: none !important;
  background: var(--content-hover-title-text) !important;
}
label.sortable-name {
  font-size: var(--font-larger) !important;
  color: var(--content-text);
  margin: 0.3rem 0 0.2rem !important;
}

.sortable-header {
  border: none !important;
  height: auto !important;
  padding: 0.25rem 1.25rem !important;
  border-bottom: 1px dotted var(--top-bar-border) !important;
  background-image: none !important;
  background: none !important;
  background-color: var(--content-bg) !important;
  position: sticky;
  top: 0;
  z-index: 2;
}
.sortable-header label {
  font-size: var(--font-normal) !important;
  color: var(--bg-muted) !important;
}

.sortable-buttons button {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  transition: color 0.15s !important;
  padding: 0.25rem 1rem !important;
  font-size: var(--font-normal) !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
  height: auto !important;
  width: auto !important;
}
.sortable-buttons button:hover {
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}
.sortable-container {
  border: 0px !important;
}
ul.sortable-list li {
  border-bottom: 1px dotted var(--top-bar-border) !important;
}
ul.sortable-list li:last-child {
  border-bottom: none !important;
}
ul.sortable-list li[draggable] {
  cursor: move;
}
ul.sortable-list .row.player .col-1 {
  cursor: pointer;
}
ul.sortable-list .row.player.selected {
  background-color: var(--search-bar-bg);
}
.sortable-container .sortable-list .active {
  background-color: var(--bg-main) !important;
  border-left: 0.35rem var(--bg-orange) solid;
}
.sortable-container .sortable-list .active .row {
  padding-left: calc(0.5rem - 0.35rem) !important;
}
.sortable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

button.btn-header {
  background: none;
  border: none;
  color: var(--content-text);
}

button.btn-header:hover,
.btn-toggle-matches:hover {
  cursor: pointer;
  color: var(--content-hover-title-text);
}

section {
  height: 100%;
}

section.emoji-mart-category {
  height: auto !important;
}

section.section-wrapper {
  padding: 0.5rem;
  margin: 0rem;
  background: var(--button-bg);
  display: flex;
  border-radius: 0.5rem;
}

/* NEW CARD LIST */
.card-flex {
  min-width: 100% !important;
  max-width: 100% !important;
}

.card-full {
  min-height: 100%;
  max-height: 100%;
  margin-bottom: 1rem;
}

.card-body.card-list {
  padding: 0;
}

.card-body.card-list .list-item {
  border-bottom: 1px dotted var(--top-bar-border);
  width: 100%;
  padding: 0.5rem 1.25rem;
  font-size: var(--font-normal);
}

.card-body.card-list .list-item:hover {
  color: var(--content-hover-title-text);
  cursor: pointer;
  background-color: var(--content-inner-border);
}

.card-body.card-list .list-item.selected {
  color: var(--content-hover-title-text);
  background-color: var(--content-inner-border);
}

.cke {
  border-radius: 0.25rem;
}

.search-bar input {
  border: none !important;
  background: none !important;
  width: 10rem;
  margin-left: 0.5rem;
  color: var(--content-text) !important;
}

.search-bar div {
  display: inline-block;
}

.search-bar {
  width: 2rem;
  line-height: 1.5rem;
  overflow: hidden;
  height: 2rem;
  transition: all 0.35s;
  padding: 0.25rem;
  border-radius: 0.25rem;
}

.search-bar.extended,
.search-bar:focus-within {
  width: 14rem;
  background-color: var(--header-search-bar-bg);
}

.pagination .page-item .page-link {
  background-color: var(--main-bg);
  border: 1px solid var(--content-inner-border);
  color: var(--content-text);
}

.pagination .page-item .page-link:hover {
  background-color: var(--content-inner-border);
}

.pagination .page-item.disabled .page-link {
  background-color: var(--main-bg);
  border: 1px solid var(--content-inner-border);
  border-color: var(--content-inner-border);
  color: var(--content-inner-border);
}

.pagination .page-item.active .page-link {
  background-color: var(--content-hover-title-text);
  border: 1px solid var(--content-inner-border);
  border-color: var(--content-inner-border);
}

.deck-photo-modal .modal-dialog {
  max-width: 75vw !important;
}

.toast {
  background: none;
}

.toast-body {
  background-color: var(--bs-body-bg);
}

.standard-toast .toast-body {
  background-color: var(--bg-orange);
  color: var(--content-text);
  border-radius: 0.25rem;
}

.standard-toast.webrtc-data-coin-flip .toast-body,
.standard-toast.webrtc-data-dice-roll .toast-body {
  font-weight: bold;
}

.standard-toast.webrtc-data-coin-flip .toast-body:before {
  content: "Coin flip resulted in: ";
  font-weight: normal;
}

.standard-toast.webrtc-data-dice-roll .toast-body:before {
  content: "Dice roll resulted in: ";
  font-weight: normal;
}

.success-toast .toast-body {
  background-color: var(--bg-success);
  color: var(--content-text);
  border-radius: 0.25rem;
}

.error-toast .toast-body {
  background-color: var(--bg-error);
  color: var(--content-text);
  border-radius: 0.25rem;
}

.warning-toast .toast-body {
  background-color: var(--bg-warning);
  color: var(--content-text);
  border-radius: 0.25rem;
}

.tooltip-large {
  min-width: 12rem;
}

/* ngb Date/Time-picker */
ngb-datepicker {
  border: 1px solid var(--content-inner-border) !important;
}

.ngb-dp-navigation-select select {
  margin: 0 0.25rem !important;
}

.ngb-dp-header {
  background-color: var(--content-bg) !important;
  border-color: var(--content-border) !important;
}

.btn-link {
  color: var(--content-text) !important;
}

.btn-link:hover {
  color: var(--content-hover-title-text) !important;
}

.ngb-dp-content .btn-light {
  color: var(--content-text) !important;
}

.ngb-dp-content .btn-light.bg-primary {
  color: var(--content-text) !important;
  background-color: var(--bg-orange) !important;
  border-radius: 0 !important;
}

.ngb-dp-content .btn-light:hover {
  color: var(--content-text) !important;
  background-color: var(--bg-orange) !important;
  border-radius: 0 !important;
}

ngb-datepicker div {
  background-color: var(--content-bg) !important;
  color: var(--content-text) !important;
}

ngb-datepicker.dropdown-menu {
  background-color: var(--content-bg) !important;
  border: 1px solid var(--content-inner-border) !important;
  top: 0.25rem !important;
}

.ngb-dp-week.ngb-dp-weekdays {
  background-color: var(--content-bg) !important;
}

.ngb-dp-weekday {
  color: var(--content-text) !important;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
  background-color: var(--bg-orange);
  color: var(--content-text);
}

.custom-day.faded {
  opacity: 0.3;
}

.form-check-input:checked {
  background-color: var(--bg-orange) !important;
  border-color: var(--bg-orange) !important;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.form-check-input:checked ~ .form-check-label::before {
  color: #fff;
  border-color: var(--bg-orange);
  background-color: var(--bg-orange);
}

.form-check-input:disabled:checked ~ .form-check-label::before,
.form-check-input:disabled ~ .form-check-label::before {
  background-color: rgb(83 89 94) !important;
  border-color: var(--bg-muted);
  color: var(--bg-muted);
}

.form-check-input:disabled:checked ~ .form-check-label::after,
.form-check-input:disabled ~ .form-check-label::after {
  background-color: var(--bg-muted);
}

.ngb-dp-content.ngb-dp-months {
  flex-wrap: wrap;
}

.ngb-tp-meridian .btn-outline-primary {
  color: #6c757d;
  border-color: #6c757d;
}

.ngb-tp-meridian .btn-outline-primary:active,
.ngb-tp-meridian .btn-outline-primary:hover {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

/* Npn Slider */
npn-slider .slider {
  height: auto !important;
}

npn-slider .slider .bar {
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: var(--content-inner-border) !important;
}

npn-slider .slider .bar div.filler {
  border: none !important;
  border-radius: none !important;
}

npn-slider .slider .bar div.filler > span {
  background-color: var(--bg-orange) !important;
  opacity: 0.5 !important;
}

npn-slider .slider .bar .left-handle,
npn-slider .slider .bar .right-handle {
  background-color: var(--bg-orange) !important;
  border-color: var(--bg-orange) !important;
}

svg.set-icon {
  min-width: 1.25rem;
  max-width: 1.25rem;
  min-height: 1.25rem;
  max-height: 1.25rem;
}

path.set-icon-path {
  fill: var(--content-text);
}

/* full calendar scrollbar */
.fc .fc-toolbar {
  flex-wrap: wrap;
  padding: 0 0.5rem;
  gap: 0.5rem;
}

.fc .fc-multimonth {
  border: 0px;
  border-top: 1px solid var(--content-inner-border);
}

.fc-view-harness,
.fc-multimonth-daygrid,
.fc-multimonth-header {
  background-color: var(--content-bg) !important;
}

.fc-daygrid-event {
  white-space: break-spaces;
}

.fc a {
  text-decoration: none;
}

.fc-daygrid-week-number {
  padding: 0 0.25rem 0 0.25rem !important;
  font-size: small;
  font-weight: lighter;
  min-width: 2rem !important;
  text-align: left !important;
  font-family: monospace;
}

.calendar-item-event.multi-day .single-day {
  display: none;
}

.calendar-item-event.single-day .multi-day {
  display: none;
}

.calendar-item-event.in-person .online {
  display: none;
}

.calendar-item-event.online .in-person {
  display: none;
}

.calendar-item-event.online .in-person {
  display: none;
}

.calendar-item-event .match-icon {
  display: none;
}

.calendar-item-match .single-day,
.calendar-item-match .multi-day,
.calendar-item-match .in-person,
.calendar-item-match .online {
  display: none;
}

.fc-scroller::-webkit-scrollbar {
  display: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid var(--content-inner-border) !important;
  border: 1px solid var(--content-inner-border) !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid var(--content-inner-border) !important;
  border: 1px solid var(--content-inner-border) !important;
}

.fc-theme-standard a {
  color: var(--content-text) !important;
}

.calendar-item-match,
.calendar-item-event {
  padding-left: 0.25rem !important;
}

.calendar-item-match.competetive {
  background-color: #00800075 !important;
  border-color: #008000b3 !important;
}

.calendar-item-match.casual {
  background-color: #009fff6b !important;
  border-color: #009fffa1 !important;
}

.calendar-item-match.waiting-response {
  opacity: 0.45;
  border-color: #f5f5f54f !important;
  background-image: linear-gradient(
    135deg,
    #1c1c1c 25%,
    #000000 25%,
    #000000 50%,
    #1c1c1c 50%,
    #1c1c1c 75%,
    #000000 75%,
    #000000 100%
  );
  background-size: 10px 10px;
}

.calendar-item-match.waiting-response .fc-event-title {
  color: whitesmoke !important;
  font-style: italic !important;
}

.calendar-item-event.multi-day:not(.small-screen):not(.fc-list-event) {
  background-color: #ffa50045 !important;
  border-color: #ffa50080 !important;
}

.calendar-item-match .fc-event-title,
.calendar-item-event.multi-day .fc-event-title,
.calendar-item-event.single-day .fc-event-title {
  color: var(--content-text) !important;
}

.calendar-item-event.single-day:not(.small-screen):not(.fc-list-event) {
  background-color: #ff009982 !important;
  border-color: #ff0099bd !important;
}

.calendar-item-availablity {
  background-color: greenyellow !important;
  color: greenyellow !important;
}

.player-info-tooltip .tooltip-inner {
  min-width: 288px !important;
  max-width: 300px !important;
  min-height: 500px !important;
  max-height: 500px !important;
  background: none;
}

.player-info-tooltip.show {
  opacity: 1;
}

.player-info-tooltip .arrow {
  display: none;
}

.top-bar-dropdown {
  margin-top: -1.75rem;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.min-width-100 {
  min-width: 100%;
}

.max-width-100 {
  max-width: 100%;
}

.min-height-100 {
  min-height: 100%;
}

.max-height-100 {
  max-height: 100%;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.border-top {
  border-top: 1px solid var(--content-inner-border) !important;
}

/* .border-bottom {
  border-bottom: 1px solid var(--content-inner-border) !important;
} */

.border-bottom-dark {
  border-bottom: 1px solid var(--top-bar-border) !important;
}

.border-left {
  border-left: 1px solid var(--content-inner-border) !important;
}

.border-right {
  border-right: 1px solid var(--content-inner-border) !important;
}

.border-dark-right {
  border-right: 1px solid var(--top-bar-border) !important;
}

.border-muted-right {
  border-right: 1px solid var(--bg-muted) !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.league-points-tooltip .tooltip-inner {
  min-width: 20rem !important;
  max-width: 20rem !important;
}

[data-device="mobile"] ngb-modal-window::-webkit-scrollbar {
  display: none;
}

.fit-content {
  min-width: fit-content !important;
  max-width: fit-content !important;
}

.fit-content .tooltip-inner {
  min-width: fit-content !important;
  max-width: fit-content !important;
}

.ngx-charts,
text {
  fill: var(--content-text);
}

.ngx-charts .active .legend-label-text {
  color: VAR(--content-hover-title-text) !important;
}

.alert-status {
  background-color: var(--bg-error);
  color: var(--white-transparent-75);
}

/* APPOINTMENT BOOKING */
.new-appointment-calendar-item.available,
.new-appointment-calendar-item.busy,
.new-appointment-calendar-item.pending {
  padding: 0.25rem;
  border: none !important;
  box-shadow: 0 0 0 1px black !important;
  border-radius: 0;
}

.new-appointment-calendar-item.opponent.available {
  background-color: #00ff1ad4;
  color: #0b7516 !important;
}

.new-appointment-calendar-item.opponent.busy {
  background-color: #b63b3b;
}

.new-appointment-calendar-item.opponent.pending {
  background-color: #ffa600;
  color: #614208 !important;
}

.new-appointment-calendar-item.player.available {
  background-color: #ffffffb3;
  color: black !important;
}

.new-appointment-calendar-item.player.busy {
  background-color: steelblue;
}

.new-appointment-calendar-item.player.pending {
  background-color: #404040;
}

.scrollable {
  overflow: auto;
}

.scrollable.vertical {
  overflow: hidden;
  overflow-y: auto;
}

.scrollable.horizontal {
  overflow: hidden;
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 15px !important;
}

::-webkit-scrollbar-track {
  width: 1rem !important;
  border-radius: 0 !important;
  border-color: rgba(var(--bs-secondary-rgb), 0.25);
  border-style: solid;
  background-color: rgba(var(--bs-secondary-rgb), 0.025);
}
::-webkit-scrollbar-track:vertical {
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 0px;
}
::-webkit-scrollbar-track:horizontal {
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  width: 8px !important;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  border-left-width: 3px;
  border-top-width: 3px;
  border-right-width: 3px;
  border-bottom-width: 3px;
  background-clip: padding-box;
  background-color: rgba(var(--bs-secondary-rgb), 0.325) !important;
  -webkit-border-radius: 8px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.scroller:hover::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  border-radius: 1rem;
  width: 8px !important;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  border-left-width: 3px;
  border-top-width: 3px;
  border-right-width: 3px;
  border-bottom-width: 3px;
  background-clip: padding-box;
  background-color: rgba(var(--bs-secondary-rgb), 0.825) !important;
  -webkit-border-radius: 8px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

/* :hover::-webkit-scrollbar-track {
  background: rgba(var(--bs-secondary-rgb), 0.125) !important;
}
:hover::-webkit-scrollbar-thumb {
  border-color: rgba(var(--bs-secondary-rgb), 0.125) !important;
}

::-webkit-scrollbar-track {
  width: 18px !important;
  border-radius: 0 !important;
  background: var(--content-bg) !important;
}

::-webkit-scrollbar {
  width: 14px !important;
} */

/* ::-webkit-scrollbar-thumb:hover {
  background: var(--top-bar-hover-text) !important;
  border: 3px solid !important;
  border-color: var(--content-bg) !important;
} */

.scrollable::-webkit-scrollbar-thumb {
  /* border: 3px solid !important;
  border-color: var(--content-bg) !important; */
  min-height: 4rem !important;
}

.bd-callout {
  padding: 0.5rem 0.75rem;
  border: 1px solid;
  border-radius: 0.25rem;
  border-color: var(--content-text);
  color: var(--content-text);
}

.bd-callout.bd-callout-muted {
  border-color: var(--bg-muted) !important;
  color: var(--bg-muted);
}

.bd-callout.bd-callout-error {
  border-color: var(--bg-error) !important;
  color: var(--bg-error);
}

.bd-callout.bd-callout-main {
  border-color: var(--bg-main) !important;
  color: var(--bg-main);
}

.bd-callout.bd-callout-warning {
  border-color: var(--bg-warning) !important;
  color: var(--bg-warning);
}

.player-id-modal .modal-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  color: black;
}

.bg-body {
  background-color: var(--bg-body) !important;
}

.bg-main {
  background-color: var(--main-bg) !important;
}

.tolaria-date-picker .modal-content {
  width: auto;
}

.tolaria-date-picker .modal-dialog {
  max-width: 96vw;
  display: flex;
  justify-content: center;
}

.letter-spacing-1 {
  letter-spacing: 0.125rem;
}

.letter-spacing-2 {
  letter-spacing: 0.25rem;
}

.letter-spacing-3 {
  letter-spacing: 0.5rem;
}

/* new tournament management */
.scrollable-noscrollbar {
  overflow-y: auto !important;
}

.scrollable-noscrollbar::-webkit-scrollbar {
  display: none !important;
}

app-tournament-management,
app-tournament-management-players {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
}

.match-room-life-total-tooltip .tooltip-inner {
  min-width: 20rem;
  max-width: 20rem;
  width: 20rem;
}

.match-room-life-history-tooltip .tooltip-inner {
  min-width: 10rem;
  max-width: 10rem;
  width: 10rem;
  min-height: auto;
  max-height: 40vh;
  height: 40vh;
}

.bg-main {
  background-color: var(--bg-main);
}

.bg-main-translucent {
  background-color: rgb(var(--bg-main), 75%);
}

.ms-cost {
  margin-right: 1px !important;
  margin-left: 0px !important;
}

.message-wrapper .message-time .hour-clock-12 {
  font-size: smaller !important;
  text-transform: lowercase;
  margin-left: -1.25rem;
}

.text-link:hover {
  text-decoration: underline;
  --bs-text-opacity: 0.75;
  cursor: pointer;
}

/* GOOGLE MAPS */
.gm-style .gm-style-iw-c {
  background: var(--main-bg);
}

.gm-style-iw-d {
  overflow: hidden;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style .gm-style-iw-tc::after {
  background: var(--main-bg);
}
.link {
  transition: all .25s;
}
.link:hover {
  color: var(--bs-primary) !important;
}



.doto {
  font-family: "Doto", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "ROND" 0;
}
.doto-800 {
  font-family: "Doto", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 800;
  font-variation-settings: "ROND" 0;
}