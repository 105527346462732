@font-face {
  font-family: 'mtg-set-icons';
  src:  url('../fonts/mtg-set-icons.eot?ylpup1');
  src:  url('../fonts/mtg-set-icons.eot?ylpup1#iefix') format('embedded-opentype'),
    url('../fonts/mtg-set-icons.ttf?ylpup1') format('truetype'),
    url('../fonts/mtg-set-icons.woff?ylpup1') format('woff'),
    url('../fonts/mtg-set-icons.svg?ylpup1#mtg-set-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.mtg-set {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'mtg-set-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 1.75rem;
  display: inline-block;
  text-align: center;
}
.mtg-set.s-2 {
  font-size: 1.5rem !important;
}

.mtg-set-tsr:before {
  content: "\e900";
}
.mtg-set-c13:before {
  content: "\e901";
}
.mtg-set-c14:before {
  content: "\e902";
}
.mtg-set-c15:before {
  content: "\e903";
}
.mtg-set-c20:before {
  content: "\e904";
}
.mtg-set-cc1:before {
  content: "\e905";
}
.mtg-set-ced:before {
  content: "\e906";
}
.mtg-set-cei:before {
  content: "\e907";
}
.mtg-set-cm1:before {
  content: "\e908";
}
.mtg-set-cma:before {
  content: "\e909";
}
.mtg-set-cmd:before {
  content: "\e90a";
}
.mtg-set-dpa:before {
  content: "\e90b";
}
.mtg-set-drb:before {
  content: "\e90c";
}
.mtg-set-exp:before {
  content: "\e90d";
}
.mtg-set-h09:before {
  content: "\e90e";
}
.mtg-set-h17:before {
  content: "\e90f";
}
.mtg-set-hop:before {
  content: "\e910";
}
.mtg-set-med:before {
  content: "\e911";
}
.mtg-set-mp2:before {
  content: "\e912";
}
.mtg-set-mps:before {
  content: "\e913";
}
.mtg-set-mtga:before {
  content: "\e914";
}
.mtg-set-mtgo:before {
  content: "\e915";
}
.mtg-set-p02:before {
  content: "\e916";
}
.mtg-set-papac:before {
  content: "\e917";
}
.mtg-set-parl:before {
  content: "\e918";
}
.mtg-set-parl2:before {
  content: "\e919";
}
.mtg-set-pbook:before {
  content: "\e91a";
}
.mtg-set-pc2:before {
  content: "\e91b";
}
.mtg-set-pca:before {
  content: "\e91c";
}
.mtg-set-pd2:before {
  content: "\e91d";
}
.mtg-set-pd3:before {
  content: "\e91e";
}
.mtg-set-pdrc:before {
  content: "\e91f";
}
.mtg-set-peuro:before {
  content: "\e920";
}
.mtg-set-pgru:before {
  content: "\e921";
}
.mtg-set-pidw:before {
  content: "\e922";
}
.mtg-set-pmei:before {
  content: "\e923";
}
.mtg-set-pmps:before {
  content: "\e924";
}
.mtg-set-pmtg2:before {
  content: "\e925";
}
.mtg-set-por:before {
  content: "\e926";
}
.mtg-set-ptg:before {
  content: "\e927";
}
.mtg-set-ptk:before {
  content: "\e928";
}
.mtg-set-pxbox:before {
  content: "\e929";
}
.mtg-set-pz1:before {
  content: "\e92a";
}
.mtg-set-s00:before {
  content: "\e92b";
}
.mtg-set-s99:before {
  content: "\e92c";
}
.mtg-set-ss1:before {
  content: "\e92d";
}
.mtg-set-ss2:before {
  content: "\e92e";
}
.mtg-set-ss3:before {
  content: "\e92f";
}
.mtg-set-ugl:before {
  content: "\e930";
}
.mtg-set-und:before {
  content: "\e931";
}
.mtg-set-unh:before {
  content: "\e932";
}
.mtg-set-ust:before {
  content: "\e933";
}
.mtg-set-v09:before {
  content: "\e934";
}
.mtg-set-v10:before {
  content: "\e935";
}
.mtg-set-v11:before {
  content: "\e936";
}
.mtg-set-v12:before {
  content: "\e937";
}
.mtg-set-v13:before {
  content: "\e938";
}
.mtg-set-v14:before {
  content: "\e939";
}
.mtg-set-v15:before {
  content: "\e93a";
}
.mtg-set-v16:before {
  content: "\e93b";
}
.mtg-set-v17:before {
  content: "\e93c";
}
.mtg-set-van:before {
  content: "\e93d";
}
.mtg-set-w16:before {
  content: "\e93e";
}
.mtg-set-w17:before {
  content: "\e93f";
}
.mtg-set-x2ps:before {
  content: "\e940";
}
.mtg-set-znc:before {
  content: "\e941";
}
.mtg-set-zne:before {
  content: "\e942";
}
.mtg-set-ath:before {
  content: "\e943";
}
.mtg-set-brb:before {
  content: "\e944";
}
.mtg-set-btd:before {
  content: "\e945";
}
.mtg-set-c16:before {
  content: "\e946";
}
.mtg-set-c17:before {
  content: "\e947";
}
.mtg-set-c18:before {
  content: "\e948";
}
.mtg-set-cm2:before {
  content: "\e949";
}
.mtg-set-ddf:before {
  content: "\e94a";
}
.mtg-set-ddi:before {
  content: "\e94b";
}
.mtg-set-ddr:before {
  content: "\e94c";
}
.mtg-set-default:before {
  content: "\e94d";
}
.mtg-set-dkm:before {
  content: "\e94e";
}
.mtg-set-gs1:before {
  content: "\e94f";
}
.mtg-set-ha1:before {
  content: "\e950";
}
.mtg-set-phuk:before {
  content: "\e951";
}
.mtg-set-psdg:before {
  content: "\e952";
}
.mtg-set-pz2:before {
  content: "\e953";
}
.mtg-set-rin:before {
  content: "\e954";
}
.mtg-set-star:before {
  content: "\e955";
}
.mtg-set-td2:before {
  content: "\e956";
}
.mtg-set-ddo:before {
  content: "\e957";
}
.mtg-set-cn2:before {
  content: "\e958";
}
.mtg-set-cns:before {
  content: "\e959";
}
.mtg-set-dci:before {
  content: "\e95a";
}
.mtg-set-dd1:before {
  content: "\e95b";
}
.mtg-set-ddj:before {
  content: "\e95c";
}
.mtg-set-ddl:before {
  content: "\e95d";
}
.mtg-set-ddn:before {
  content: "\e95e";
}
.mtg-set-past:before {
  content: "\e95f";
}
.mtg-set-ddc:before {
  content: "\e960";
}
.mtg-set-dde:before {
  content: "\e961";
}
.mtg-set-ddk:before {
  content: "\e962";
}
.mtg-set-ddm:before {
  content: "\e963";
}
.mtg-set-dds:before {
  content: "\e964";
}
.mtg-set-ddu:before {
  content: "\e965";
}
.mtg-set-e01:before {
  content: "\e966";
}
.mtg-set-cmr:before {
  content: "\e967";
}
.mtg-set-ddt:before {
  content: "\e968";
}
.mtg-set-e02:before {
  content: "\e969";
}
.mtg-set-gn2:before {
  content: "\e96a";
}
.mtg-set-md1:before {
  content: "\e96b";
}
.mtg-set-me3:before {
  content: "\e96c";
}
.mtg-set-arc:before {
  content: "\e96d";
}
.mtg-set-c19:before {
  content: "\e96e";
}
.mtg-set-dd2:before {
  content: "\e96f";
}
.mtg-set-ddd:before {
  content: "\e970";
}
.mtg-set-ddg:before {
  content: "\e971";
}
.mtg-set-ddh:before {
  content: "\e972";
}
.mtg-set-ddp:before {
  content: "\e973";
}
.mtg-set-ddq:before {
  content: "\e974";
}
.mtg-set-gnt:before {
  content: "\e975";
}
.mtg-set-khc:before {
  content: "\e976";
}
.mtg-set-me1:before {
  content: "\e977";
}
.mtg-set-me2:before {
  content: "\e978";
}
.mtg-set-me4:before {
  content: "\e979";
}
.mtg-set-mh1:before {
  content: "\e97a";
}
.mtg-set-mm2:before {
  content: "\e97b";
}
.mtg-set-psal:before {
  content: "\e97c";
}
.mtg-set-ren:before {
  content: "\e97d";
}
.mtg-set-td0:before {
  content: "\e97e";
}
.mtg-set-ed:before {
  content: "\e97f";
}
.mtg-set-ed1:before {
  content: "\e980";
}
.mtg-set-a25:before {
  content: "\e981";
}
.mtg-set-bbd:before {
  content: "\e982";
}
.mtg-set-chr:before {
  content: "\e983";
}
.mtg-set-ema:before {
  content: "\e984";
}
.mtg-set-mm3:before {
  content: "\e985";
}
.mtg-set-tpr:before {
  content: "\e986";
}
.mtg-set-ed2:before {
  content: "\e987";
}
.mtg-set-xm:before {
  content: "\e988";
}
.mtg-set-ed3:before {
  content: "\e989";
}
.mtg-set-ed4:before {
  content: "\e98a";
}
.mtg-set-ed5:before {
  content: "\e98b";
}
.mtg-set-e:before {
  content: "\e98c";
}
.mtg-set-akr:before {
  content: "\e98d";
}
.mtg-set-all:before {
  content: "\e98e";
}
.mtg-set-apc:before {
  content: "\e98f";
}
.mtg-set-arn:before {
  content: "\e990";
}
.mtg-set-atq:before {
  content: "\e991";
}
.mtg-set-drk:before {
  content: "\e992";
}
.mtg-set-fem:before {
  content: "\e993";
}
.mtg-set-ima:before {
  content: "\e994";
}
.mtg-set-lea:before {
  content: "\e995";
}
.mtg-set-leb:before {
  content: "\e996";
}
.mtg-set-leg:before {
  content: "\e997";
}
.mtg-set-m11:before {
  content: "\e998";
}
.mtg-set-m12:before {
  content: "\e999";
}
.mtg-set-m14:before {
  content: "\e99a";
}
.mtg-set-m15:before {
  content: "\e99b";
}
.mtg-set-m19:before {
  content: "\e99c";
}
.mtg-set-m20:before {
  content: "\e99d";
}
.mtg-set-m21:before {
  content: "\e99e";
}
.mtg-set-ori:before {
  content: "\e99f";
}
.mtg-set-planeswalker:before {
  content: "\e9a0";
}
.mtg-set-psum:before {
  content: "\e9a1";
}
.mtg-set-uma:before {
  content: "\e9a2";
}
.mtg-set-vis:before {
  content: "\e9a3";
}
.mtg-set-vma:before {
  content: "\e9a4";
}
.mtg-set-wth:before {
  content: "\e9a5";
}
.mtg-set-klr:before {
  content: "\e9a6";
}
.mtg-set-ed6:before {
  content: "\e9a7";
}
.mtg-set-ed7:before {
  content: "\e9a8";
}
.mtg-set-exo:before {
  content: "\e9a9";
}
.mtg-set-hml:before {
  content: "\e9aa";
}
.mtg-set-jmp:before {
  content: "\e9ab";
}
.mtg-set-m10:before {
  content: "\e9ac";
}
.mtg-set-m13:before {
  content: "\e9ad";
}
.mtg-set-mma:before {
  content: "\e9ae";
}
.mtg-set-tmp:before {
  content: "\e9af";
}
.mtg-set-uds:before {
  content: "\e9b0";
}
.mtg-set-ulg:before {
  content: "\e9b1";
}
.mtg-set-ice:before {
  content: "\e9b2";
}
.mtg-set-mir:before {
  content: "\e9b3";
}
.mtg-set-mmq:before {
  content: "\e9b4";
}
.mtg-set-sth:before {
  content: "\e9b5";
}
.mtg-set-dis:before {
  content: "\e9b6";
}
.mtg-set-lgn:before {
  content: "\e9b7";
}
.mtg-set-nem:before {
  content: "\e9b8";
}
.mtg-set-ons:before {
  content: "\e9b9";
}
.mtg-set-pls:before {
  content: "\e9ba";
}
.mtg-set-usg:before {
  content: "\e9bb";
}
.mtg-set-bok:before {
  content: "\e9bc";
}
.mtg-set-gpt:before {
  content: "\e9bd";
}
.mtg-set-inv:before {
  content: "\e9be";
}
.mtg-set-jud:before {
  content: "\e9bf";
}
.mtg-set-pcy:before {
  content: "\e9c0";
}
.mtg-set-tor:before {
  content: "\e9c1";
}
.mtg-set-dn:before {
  content: "\e9c2";
}
.mtg-set-ala:before {
  content: "\e9c3";
}
.mtg-set-chk:before {
  content: "\e9c4";
}
.mtg-set-csp:before {
  content: "\e9c5";
}
.mtg-set-dst:before {
  content: "\e9c6";
}
.mtg-set-eve:before {
  content: "\e9c7";
}
.mtg-set-fut:before {
  content: "\e9c8";
}
.mtg-set-lrw:before {
  content: "\e9c9";
}
.mtg-set-mor:before {
  content: "\e9ca";
}
.mtg-set-mrd:before {
  content: "\e9cb";
}
.mtg-set-ody:before {
  content: "\e9cc";
}
.mtg-set-plc:before {
  content: "\e9cd";
}
.mtg-set-rav:before {
  content: "\e9ce";
}
.mtg-set-scg:before {
  content: "\e9cf";
}
.mtg-set-shm:before {
  content: "\e9d0";
}
.mtg-set-tsp:before {
  content: "\e9d1";
}
.mtg-set-wwk:before {
  content: "\e9d2";
}
.mtg-set-con:before {
  content: "\e9d3";
}
.mtg-set-arb:before {
  content: "\e9d4";
}
.mtg-set-mbs:before {
  content: "\e9d5";
}
.mtg-set-roe:before {
  content: "\e9d6";
}
.mtg-set-sok:before {
  content: "\e9d7";
}
.mtg-set-zen:before {
  content: "\e9d8";
}
.mtg-set-avr:before {
  content: "\e9d9";
}
.mtg-set-dka:before {
  content: "\e9da";
}
.mtg-set-gtc:before {
  content: "\e9db";
}
.mtg-set-isd:before {
  content: "\e9dc";
}
.mtg-set-nph:before {
  content: "\e9dd";
}
.mtg-set-rtr:before {
  content: "\e9de";
}
.mtg-set-som:before {
  content: "\e9df";
}
.mtg-set-ths:before {
  content: "\e9e0";
}
.mtg-set-aer:before {
  content: "\e9e1";
}
.mtg-set-bfz:before {
  content: "\e9e2";
}
.mtg-set-bng:before {
  content: "\e9e3";
}
.mtg-set-dgm:before {
  content: "\e9e4";
}
.mtg-set-dtk:before {
  content: "\e9e5";
}
.mtg-set-emn:before {
  content: "\e9e6";
}
.mtg-set-frf:before {
  content: "\e9e7";
}
.mtg-set-jou:before {
  content: "\e9e8";
}
.mtg-set-kld:before {
  content: "\e9e9";
}
.mtg-set-ktk:before {
  content: "\e9ea";
}
.mtg-set-ogw:before {
  content: "\e9eb";
}
.mtg-set-soi:before {
  content: "\e9ec";
}
.mtg-set-akh:before {
  content: "\e9ed";
}
.mtg-set-hou:before {
  content: "\e9ee";
}
.mtg-set-rix:before {
  content: "\e9ef";
}
.mtg-set-xln:before {
  content: "\e9f0";
}
.mtg-set-dom:before {
  content: "\e9f1";
}
.mtg-set-grn:before {
  content: "\e9f2";
}
.mtg-set-rna:before {
  content: "\e9f3";
}
.mtg-set-war:before {
  content: "\e9f4";
}
.mtg-set-eld:before {
  content: "\e9f5";
}
.mtg-set-iko:before {
  content: "\e9f6";
}
.mtg-set-khm:before {
  content: "\e9f7";
}
.mtg-set-thb:before {
  content: "\e9f8";
}
.mtg-set-znr:before {
  content: "\e9f9";
}
