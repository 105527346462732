.mana-symbol {
    background-image: url('../sprites/mana-symbols.png');
    background-repeat: no-repeat;
    display: block;
    width: 36px;
    height: 36px;
    transform: scale(0.625);
}

.mana-symbol-inline {
    display: inline-block;
    vertical-align: middle;
}

.mana-symbol-0 {
    background-position: -2px -2px;
}

.mana-symbol-1 {
    background-position: -42px -2px;
}

.mana-symbol-2 {
    background-position: -82px -2px;
}

.mana-symbol-3 {
    background-position: -122px -2px;
}

.mana-symbol-4 {
    background-position: -162px -2px;
}

.mana-symbol-5 {
    background-position: -202px -2px;
}

.mana-symbol-6 {
    background-position: -242px -2px;
}

.mana-symbol-7 {
    background-position: -282px -2px;
}

.mana-symbol-8 {
    background-position: -322px -2px;
}

.mana-symbol-9 {
    background-position: -362px -2px;
}

.mana-symbol-B {
    background-position: -402px -2px;
}

.mana-symbol-G {
    background-position: -442px -2px;
}

.mana-symbol-R {
    background-position: -482px -2px;
}

.mana-symbol-T {
    background-position: -522px -2px;
}

.mana-symbol-U {
    background-position: -562px -2px;
}

.mana-symbol-W {
    background-position: -602px -2px;
}

.mana-symbol-X {
    background-position: -642px -2px;
}