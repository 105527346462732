.ngb-dp-months {
  display: flex;
  justify-content: center;
}

.flex-wrap .ngb-dp-content.ngb-dp-months {
  flex-wrap: wrap;
}

.modal-body,
.modal-header,
.modal-footer {
  background-color: var(--content-bg);
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bg-muted) !important;
}