:root {
  --card-width: 146px;
  --card-height: 204px;
  --card-radius: 8px;

  --cropper-overlay-color: rgba(0,0,0,0);
  --card-width: 146px;
  --card-height: 204px;
  --card-radius: 8px;

  /* --left-bar-width: 50px; */
  --left-bar-width: 0px;
  --top-bar-height: 3rem;
  --chat-width: 250px;
  --white-transparent-5: rgba(255, 255, 255, 0.05);
  --white-transparent-10: rgba(255, 255, 255, 0.1);
  --white-transparent-15: rgba(255, 255, 255, 0.15);
  --white-transparent-25: rgba(255, 255, 255, 0.25);
  --white-transparent-50: rgba(255, 255, 255, 0.5);
  --white-transparent-75: rgba(255, 255, 255, 0.75);
  --black-transparent-5: rgba(0, 0, 0, 0.05);
  --black-transparent-10: rgba(0, 0, 0, 0.1);
  --black-transparent-15: rgba(0, 0, 0, 0.15);
  --black-transparent-25: rgba(0, 0, 0, 0.25);
  --black-transparent-50: rgba(0, 0, 0, 0.5);
  --black-transparent-75: rgba(0, 0, 0, 0.75);

  --bg-primary: #0d6efd;
  --bg-orange: #ff6c2c;
  --bg-dark-orange: #c2582a;
  --bg-success: #28a745;
  --bg-error: #dc3545;
  --bg-error-hover: #bd2130;
  --bg-warning: #ffc107;
  --bg-muted: #6a747d;
  --bg-dark-muted: #424446;
  --bg-main: 245, 245, 245;

  --font-xxsmall: 0.5rem;
  --font-xsmall: 0.66rem;
  --font-smaller: 0.75rem;
  --font-small: 0.825rem;
  --font-normal: 1rem;
  --font-large: 1.25rem;
  --font-larger: 1.5rem;
  --font-xlarge: 1.75rem;
  --font-xxlarge: 2rem;
  --font-mega: 5rem;
  --font-jumbo: 8rem;

  --main-bg: #f5f5f5;
  --top-bar-bg: #fff;
  --top-bar-border: #d9e0e2;
  --top-bar-text: #4e5760;
  --top-bar-hover-bg: #adadad;
  --top-bar-hover-text: #ff6c2c;
  --top-bar-badge-bg: #d03f00cc;
  --top-bar-drop-down-bg: #fff;
  --top-bar-drop-down-text: #4e5760;
  --top-bar-drop-down-border: #d9e0e2;
  --top-bar-drop-down-border-bottom: #dae0e2; /* should be dotted */
  --top-bar-drop-down-hover-bg: #293a4a;
  --top-bar-drop-down-hover-text: #fff;
  --left-bar-bg: #fff;
  --left-bar-text: #767676;
  --left-bar-border: #dae1e3;
  --left-bar-hover-bg: #adadad;
  --left-bar-hover-text: #ff6c2c;
  --content-bg: #fff;
  --content-text: #000000;
  --content-hover-bg: #eae9e9;
  --content-hover-title-text: #ff6c2c;
  --content-hover-border: rgba(0, 0, 0, 0.125);
  --content-inner-bg: #f9f9f9;
  --content-inner-border: #f0f0f0;
  --search-bar-bg: #dae0e2;
  --chat-bg: #f9f9f9;
  --chat-text: black;
  --chat-input-bg: #f0f0f0;
  --chat-border: #dae1e3;
  --chat-message-info-text: #242424cc;
  --chat-hover-bg: #dae1e3;
  --chat-hover-text: #ff6c2c;
  --chat-active-user-bg: #f0f0f0;
  --report-slip-btn-report-bg: #6c757d;
  --report-slip-btn-report-selected-bg: #ff6c2c;
  --report-slip-hover-btn-report-bg: #283a4b;
  --report-slip-hover-btn-report-text: #fff;
  --card-wrapper-box-shadow: rgba(0, 0, 0, 0.75);
  --user-menu-signout-hover-bg: darkred;
}
[data-text-size="large"] {
  --font-xxsmall: 0.625rem;
  --font-xsmall: 0.825rem;
  --font-smaller: 0.9375rem;
  --font-small: 1.03125rem;
  --font-normal: 1.25rem;
  --font-large: 1.5625rem;
  --font-larger: 1.875rem;
  --font-xlarge: 2.1875rem;
  --font-xxlarge: 2.5rem;
  --font-huge: 3.75rem;
  --font-mega: 6.25rem;
}
[data-text-size="normal"] {
  --font-xxsmall: 0.5rem;
  --font-xsmall: 0.66rem;
  --font-smaller: 0.75rem;
  --font-small: 0.825rem;
  --font-normal: 1rem;
  --font-large: 1.25rem;
  --font-larger: 1.5rem;
  --font-xlarge: 1.75rem;
  --font-xxlarge: 2rem;
  --font-huge: 3rem;
  --font-mega: 5rem;
}
[data-text-size="medium"] {
  --font-xxsmall: 0.45rem;
  --font-xsmall: 0.594rem;
  --font-smaller: 0.675rem;
  --font-small: 0.7425rem;
  --font-normal: 0.9rem;
  --font-large: 1.125rem;
  --font-larger: 1.35rem;
  --font-xlarge: 1.575rem;
  --font-xxlarge: 1.8rem;
  --font-huge: 2.7rem;
  --font-mega: 4.5rem;
}
[data-text-size="small"] {
  --font-xxsmall: 0.4rem;
  --font-xsmall: 0.528rem;
  --font-smaller: 0.6rem;
  --font-small: 0.66rem;
  --font-normal: 0.8rem;
  --font-large: 1rem;
  --font-larger: 1.2rem;
  --font-xlarge: 1.4rem;
  --font-xxlarge: 1.6rem;
  --font-huge: 2.4rem;
  --font-mega: 4rem;
}
[data-text-size="smaller"] {
  --font-xxsmall: 0.375rem;
  --font-xsmall: 0.495rem;
  --font-smaller: 0.5625rem;
  --font-small: 0.61875rem;
  --font-normal: 0.75rem;
  --font-large: 0.9375rem;
  --font-larger: 1.125rem;
  --font-xlarge: 1.3125rem;
  --font-xxlarge: 1.5rem;
  --font-huge: 2.25rem;
  --font-mega: 3.75rem;
}
[data-theme="dark"] {
  /* DARK MODE */

  --message-form-bg: #3b3b3b;
  --message-form-border-color: rgba(115, 115, 115, 0.75);
  --message-form-border-color-focus: rgb(150, 150, 150);
  --message-form-color: rgb(255, 255, 255);
  --message-form-button-color: #888;
  --message-form-button-color-no-focus: rgb(150, 150, 150);
  --message-form-button-color-hover: white;
  --message-form-button-selected: white;
  --message-form-button-bg-active: #505050;
  
  --bg-main: 29, 29, 29;
  --overlay-color: rgba(0,0,0,0.75);
  --svg-icon: invert(1);
  --card-header-bg: rgba(0,0,0,.15);
  --hr-border-top: 1px solid rgba(255,255,255,.1);
  --svg-invert: 1;
  --box-shadow-10: rgba(255, 255, 255, 0.1);
  --main-bg: #1d1d1d;
  --bg-body: #262626;
  --top-bar-bg: #262626;
  --top-bar-border: #191919;
  --top-bar-text: #777;
  --top-bar-hover-bg: #2d2d2d;
  --top-bar-hover-text: #ff6c2c;
  --top-bar-badge-bg: #d03f00cc;
  --top-bar-drop-down-bg: #262626;
  --top-bar-drop-down-text: #d7d7d7;
  --top-bar-drop-down-border: #191919;
  --top-bar-drop-down-border-bottom: #313131;
  --top-bar-drop-down-border-top: #181818; /* should be dotted */
  --top-bar-drop-down-hover-bg: #283a4b;
  --top-bar-drop-down-hover-text: #fff;
  --left-bar-bg: #262626;
  --left-bar-text: #e0e0e0;
  --left-bar-border: #191919;
  --left-bar-hover-bg: #2d2d2d;
  --left-bar-hover-text: #ff6c2c;
  --content-bg: #222222;
  --content-bg-25: rgba(34, 34, 34, 0.25);
  --content-bg-50: rgba(34, 34, 34, 0.50);
  --content-bg-75: rgba(34, 34, 34, 0.75);
  --content-text: #e0e0e0;
  --content-text-inverted: #000000;
  --content-hover-bg: #3c3c3c;
  --content-hover-title-text: #ff6c2c;
  --content-hover-border: #191919;
  --content-inner-bg: #262626;
  --content-inner-border: #333333;
  --search-bar-bg: #313131;
  --chat-bg: #262626;
  --chat-text: #e0e0e0;
  --chat-input-bg: #333333;
  --chat-input-border: #4e4e4e;
  --chat-emoji-icon: #e0e0e0;
  --chat-border: #191919;
  --chat-message-info-text: #a1a1a1cc;
  --chat-hover-bg: #191919;
  --chat-hover-text: #ff6c2c;
  --chat-active-user-bg: #191919;
  --report-slip-btn-report-bg: #6c757d;
  --report-slip-btn-report-selected-bg: #ff6c2c;
  --report-slip-hover-btn-report-bg: #283a4b;
  --report-slip-hover-btn-report-text: #fff;
  --card-wrapper-box-shadow: rgba(255, 255, 255, 0.15);
  --bracket-match-bg: #6c757d;
  --message-toast-shadow: rgba(255, 255, 255, 0.2);
  --message-toast-bg: #ff6c2c;
  --message-toast-color: #262626;
  --button-bg: #ff6c2c;
  --button-hover-bg: #c55120;
  --droppable-bg: #52575d;
  --droppable-hover-bg: #ff6c2c;
  --header-search-bar-bg: #333333;
  --modal-dialog-border: #383838;
  --modal-dialog-bg: #313131;
  --list-item-hover-light: #484848;
  --message-group-new-messages: #383838;
  --transparent-10: rgba(255, 255, 255, 0.10);
  --transparent-25: rgba(255, 255, 255, 0.25);
  --transparent-50: rgba(255, 255, 255, 0.5);
  --transparent-75: rgba(255, 255, 255, 0.75);
  --dropdown-divider-bg: rgba(255, 255, 255, 0.17);
}
[data-theme="light"] {
  /* LIGHT MODE */

  --message-form-bg: #ffffff;
  --message-form-border-color: rgb(203 203 203);
  --message-form-border-color-focus: rgb(203 203 203);
  --message-form-color: #212529;
  --message-form-button-color: #888;
  --message-form-button-color-no-focus: rgb(150, 150, 150);
  --message-form-button-color-hover: black;
  --message-form-button-selected: black;
  --message-form-button-bg-active: #505050;

  --bg-main: 245, 245, 245;
  --overlay-color: rgba(255,255,255,0.75);
  --svg-icon: invert(1);
  --card-header-bg: rgba(0,0,0,.03);
  --hr-border-top: 1px solid rgba(0,0,0,.1);
  --svg-invert: 0;
  --box-shadow-10: rgba(0, 0, 0, 0.1);
  --main-bg: #f5f5f5;
  --bg-body: #fff;
  --top-bar-bg: #fff;
  --top-bar-border: #d9e0e2;
  --top-bar-text: #4e5760;
  --top-bar-hover-bg: #adadad;
  --top-bar-hover-text: #ff6c2c;
  --top-bar-badge-bg: #d03f00cc;
  --top-bar-drop-down-bg: #fff;
  --top-bar-drop-down-text: #4e5760;
  --top-bar-drop-down-border: #d9e0e2;
  --top-bar-drop-down-border-bottom: #dae0e2; /* should be dotted */
  --top-bar-drop-down-hover-bg: #293a4a;
  --top-bar-drop-down-hover-text: #fff;
  --left-bar-bg: #fff;
  --left-bar-text: #767676;
  --left-bar-border: #dae1e3;
  --left-bar-hover-bg: #adadad;
  --left-bar-hover-text: #ff6c2c;
  --content-bg: #ffffff;
  --content-bg-25: rgba(255, 255, 255, 0.25);
  --content-bg-50: rgba(255, 255, 255, 0.5);
  --content-bg-75: rgba(255, 255, 255, 0.75);
  --content-text: #000000;
  --content-text-inverted: #e0e0e0;
  --content-hover-bg: #f9f9f9;
  --content-hover-title-text: #ff6c2c;
  --content-hover-border: rgba(0, 0, 0, 0.125);
  --content-inner-bg: #eae9e9;
  --content-inner-border: #f0f0f0;
  --search-bar-bg: #dae0e2;
  --chat-bg: #262626;
  --chat-text: #000000;
  --chat-input-bg: #ffffff;
  --chat-input-border: #d9e0e2;
  --chat-emoji-icon: #767676;
  --chat-message-info-text: #242424cc;
  --chat-hover-bg: #dae1e3;
  --chat-hover-text: #ff6c2c;
  --chat-active-user-bg: #f0f0f0;
  --report-slip-btn-report-bg: #6c757d;
  --report-slip-btn-report-selected-bg: #ff6c2c;
  --report-slip-hover-btn-report-bg: #283a4b;
  --report-slip-hover-btn-report-text: #fff;
  --card-wrapper-box-shadow: rgba(0, 0, 0, 0.75);
  --bracket-match-bg: #f5f5f5;
  --message-toast-shadow: rgba(0, 0, 0, 0.2);
  --message-toast-bg: #4e5760;
  --message-toast-color: #000000;
  --button-bg: #ff6c2c;
  --button-hover-bg: #c55120;
  --droppable-bg: #f5f5f5;
  --droppable-hover-bg: #ff6c2c;
  --header-search-bar-bg: #dadada;
  --modal-dialog-border: #eeeeee;
  --modal-dialog-bg: #c4c9ca;
  --list-item-hover-light: #cecece;
  --message-group-new-messages: #c4c9ca;
  --transparent-10: rgba(0, 0, 0, 0.10);
  --transparent-25: rgba(0, 0, 0, 0.25);
  --transparent-50: rgba(0, 0, 0, 0.5);
  --transparent-75: rgba(0, 0, 0, 0.75);
  --dropdown-divider-bg: rgba(0, 0, 0, 0.17);
}
[data-device="mobile"] {
  --left-bar-width: 0;
}
