/* SIZING */
.text-large {
  font-size: var(--font-large) !important;
}
.text-larger {
  font-size: var(--font-larger) !important;
}
.text-xlarge {
  font-size: var(--font-xlarge) !important;
}
.text-xxlarge {
  font-size: var(--font-xxlarge) !important;
}
.text-normal {
  font-size: var(--font-normal) !important;
}
.text-medium {
  font-size: var(--font-small) !important;
}
.text-small {
  font-size: var(--font-smaller) !important;
}
.text-smaller {
  font-size: var(--font-xsmall) !important;
}
.text-huge {
  font-size: var(--font-huge) !important;
}
.text-mega {
  font-size: var(--font-mega) !important;
}
.text-jumbo {
  font-size: var(--font-jumbo) !important;
}

/* COLORING */
.text-normal {
  color: var(--content-text);
}
.text-white {
  color: white !important;
}
.text-red {
  color: #dc3545 !important;
}
.text-green {
  color: green !important;
}
.text-greenyellow {
  color: greenyellow !important;
}
.text-orange {
  color: var(--bg-orange) !important;
}
.text-warning {
  color: var(--bg-warning) !important;
}
.text-success {
  color: var(--bg-success) !important;
}

/* STYLE */
.text-bold {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}
.text-no-wrap {
  white-space: nowrap;
}
.text-keyboard-command {
  font-family: monospace;
  color: var(--content-text-inverted);
  background-color: var(--content-text);
  padding: 0.25rem .5rem;
  border-radius: .25rem;
}

/* FONT */
.text-monospaced {
  font-family: monospace;
}
.text-striked {
  text-decoration: line-through;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
