.mobile-wrapper {
  position: fixed;
  top: var(--top-bar-height);
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
