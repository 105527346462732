
.card-frame {
    background-image: url('../sprites/card-frames.png');
    background-repeat: no-repeat;
    display: block;
    transform: scale(0.65);
    transform-origin: top left;
    z-index: 10;
    position: absolute;
}

.card-frame-a {
    width: 695px;
    height: 980px;
    background-position: 0 0;
}

.card-frame-b {
    width: 695px;
    height: 980px;
    background-position: -695px 0;
}

.card-frame-g {
    width: 695px;
    height: 980px;
    background-position: -1390px 0;
}

.card-frame-l-c {
    width: 695px;
    height: 980px;
    background-position: -2085px 0;
}

.card-frame-l-b {
    width: 695px;
    height: 980px;
    background-position: -2780px 0;
}

.card-frame-l-bg {
    width: 695px;
    height: 980px;
    background-position: 0 -980px;
}

.card-frame-l-br {
    width: 695px;
    height: 980px;
    background-position: -695px -980px;
}

.card-frame-l-ub {
    width: 695px;
    height: 980px;
    background-position: -1390px -980px;
}

.card-frame-l-wb {
    width: 695px;
    height: 980px;
    background-position: -2085px -980px;
}

.card-frame-l-g {
    width: 695px;
    height: 980px;
    background-position: -2780px -980px;
}

.card-frame-l-rg {
    width: 695px;
    height: 980px;
    background-position: 0 -1960px;
}

.card-frame-l-gu {
    width: 695px;
    height: 980px;
    background-position: -695px -1960px;
}

.card-frame-l-r {
    width: 695px;
    height: 980px;
    background-position: -1390px -1960px;
}

.card-frame-l-ur {
    width: 695px;
    height: 980px;
    background-position: -2085px -1960px;
}

.card-frame-l-rw {
    width: 695px;
    height: 980px;
    background-position: -2780px -1960px;
}

.card-frame-l-u {
    width: 695px;
    height: 980px;
    background-position: 0 -2940px;
}

.card-frame-l-w {
    width: 695px;
    height: 980px;
    background-position: -695px -2940px;
}

.card-frame-l-gw {
    width: 695px;
    height: 980px;
    background-position: -1390px -2940px;
}

.card-frame-l-wu {
    width: 695px;
    height: 980px;
    background-position: -2085px -2940px;
}

.card-frame-m {
    width: 695px;
    height: 980px;
    background-position: -2780px -2940px;
}

.card-frame-r {
    width: 695px;
    height: 980px;
    background-position: -3475px 0;
}

.card-frame-u {
    width: 695px;
    height: 980px;
    background-position: -3475px -980px;
}

.card-frame-w {
    width: 695px;
    height: 980px;
    background-position: -3475px -1960px;
}

.card-frame-raster {
    width: 586px;
    height: 474px;
    background-position: -3485px -2960px;
}
