:host {
    position: relative;
}
[data-theme="light"] {
    --content-text: #000000;
    --composer-code-bg: #dddddd;
    --composer-code-text: #e7af2b;
    --composer-border: #6c757d;
    --composer-border-focused: rgb(0, 0, 0);
    --composer-inner-border: rgb(108 117 125 / 0.5);
    --composer-btn-hover-bg: rgb(0 0 0 / 5%);
    --emoji-picker-bg: #f8f9fa;
    --emoji-picker-category-bg: #f8f9fa;
    --emoji-picker-search-bg: #f8f9fa;
    --emoji-picker-scrollbar-thumb: #6c757d;
}

[data-theme="dark"] {
    --content-text: #e0e0e0;
    --composer-code-bg: #5b5b5b;
    --composer-code-text: #e72b2b;
    --composer-border: #6c757d;
    --composer-border-focused: rgb(255, 255, 255);
    --composer-inner-border: rgb(108 117 125 / 0.5);
    --composer-btn-hover-bg: rgb(255 255 255 / 5%);
    --emoji-picker-bg: #212529;
    --emoji-picker-category-bg: #212529;
    --emoji-picker-search-bg: #212529;
    --emoji-picker-scrollbar-thumb: #6c757d;
}

.ql-tooltip {
    display: none;
}

.tolaria-wysiwyg-container {
    display: flex;
    flex-direction: column;
    background: var(--bs-body-bg);
    border-radius: 0.325rem;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(var(--bs-secondary-rgb), 0.325);
    padding: 0 0.325rem;
}

.tolaria-wysiwyg-container.focused {
    border-color: var(--bs-secondary);
}

.tolaria-wysiwyg-container.silent-mode {
    border-color: rgba(var(--bs-warning-rgb), 0.325);
}

.tolaria-wysiwyg-container.silent-mode.focused {
    border-color: rgba(var(--bs-warning-rgb), 0.75);
}

.ql-editor p {
    margin: 0;
}

.ql-hidden {
    display: none;
}

.ql-editor {
    vertical-align: baseline;
    font-size: 1rem;
    max-height: 30rem;
    overflow: hidden auto;
    outline: none;
    position: relative;
    padding: 0.5rem 0.25rem 0;
    white-space-collapse: preserve;
}

.ql-container.ql-disabled .ql-editor {
    height: auto;
    max-height: unset;
}

.ql-editor::-webkit-scrollbar {
    width: 16px;
}

.ql-editor::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 8px;
    width: 8px;
}

.ql-editor::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 8px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

.ql-editor:hover::-webkit-scrollbar-thumb {
    background-color: var(--bs-secondary);
}

.ql-editor.ql-blank:before {
    color: var(--bs-secondary);
    content: attr(data-placeholder);
    font-style: italic;
    pointer-events: none;
    position: absolute;
}


/* TOOLBAR */
.ql-toolbar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.toolbar-separator {
    width: 1px;
    background-color: var(--bs-secondary);
    height: 1rem;
    margin: 2px 4px;
}

.ql-toolbar .ql-formats {
    gap: 0.125rem;
    display: flex;
    align-items: center;
}

.ql-formats {
    gap: 0.125rem;
}

.ql-button .ql-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ql-button .ql-button-icon svg {
    height: 100%;
}

.ql-button-icon.selected:after {
    content: "";
    background: var(--content-text);
    border-radius: 9999px;
    width: 75%;
    height: 1.5px;
    transition: opacity 80ms, transform 80ms;
    position: absolute;
    bottom: 4px;
    left: 50%;
    opacity: 1;
    transform: translate(-50%);
}

.svg-evenodd {
    clip-rule: evenodd;
    overflow-clip-margin: content-box;
    fill-rule: evenodd;
}

.ql-formats button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 32px;
    width: 32px;
    color: var(--bs-secondary);
    outline: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ql-toolbar .ql-header.ql-picker {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 32px;
    border-radius: 0.25rem;
    padding: 0.25rem 0.125rem 0.25rem 0.25rem;
    color: rgba(var(--bs-secondary-rgb), 0.325);
}

.ql-toolbar .ql-header.ql-picker:hover,
.ql-toolbar button:hover,
.ql-formats button:hover {
    border-color: rgba(0, 0, 0, 0) !important;
    background-color: var(--composer-btn-hover-bg);
}

.ql-toolbar .ql-header.ql-picker.ql-expanded .ql-picker-item {
    color: var(--content-text);
}

.ql-toolbar button svg {
    float: left;
    height: 100%;
}

.ql-toolbar button .ql-stroke {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
}

.ql-toolbar button .ql-thin {
    stroke-width: 1;
}

.ql-toolbar button.ql-active,
.ql-button.ql-active {
    color: var(--content-text);
}

.ql-toolbar button .ql-stroke,
.ql-toolbar button.ql-active .ql-stroke,
.ql-toolbar .ql-picker-label .ql-stroke {
    stroke: rgba(var(--bs-secondary-rgb), 0.325);
}

.ql-toolbar button .ql-fill,
.ql-toolbar button.ql-active .ql-fill,
.ql-toolbar .ql-picker-label .ql-stroke {
    fill: rgba(var(--bs-secondary-rgb), 0.325);
}

.focused .ql-toolbar button .ql-stroke {
    stroke: var(--bs-secondary);
}

.focused .ql-toolbar button.ql-active .ql-stroke,
.focused .ql-picker-label .ql-stroke {
    stroke: var(--content-text);
}

.focused .ql-toolbar button .ql-fill,
.focused .ql-picker-label .ql-stroke {
    fill: var(--bs-secondary);
}

.focused .ql-toolbar button.ql-active .ql-fill {
    fill: var(--content-text);
}

.focused .ql-header.ql-picker {
    color: var(--content-text);
}

.toolbar-hidden .ql-toolbar {
    display: none;
}

.ql-toolbar .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    font-size: calc(1.375rem + 1.5vw);
}

.ql-toolbar .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    font-size: calc(1.325rem + .9vw);
}

.ql-toolbar .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    font-size: calc(1.3rem + .6vw);
}

.ql-toolbar .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    font-size: calc(1.275rem + .3vw);
}

.ql-toolbar .ql-picker.ql-header .ql-picker-label::before {
    font-size: 1rem;
}

.ql-toolbar .ql-picker.ql-header .ql-picker-label::before,
.ql-toolbar .ql-picker.ql-header .ql-picker-item::before {
    content: "Normal";
}

.ql-toolbar .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-toolbar .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: "Heading 1";
}

.ql-toolbar .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-toolbar .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: "Heading 2";
}

.ql-toolbar .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-toolbar .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: "Heading 3";
}

.ql-toolbar .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-toolbar .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: "Heading 4";
}

.ql-toolbar .ql-picker-label svg {
    height: 1rem;
}

.ql-toolbar .ql-picker-options {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: var(--main-bg);
    padding: 0.5rem 0rem;
    top: -7.25rem;
    left: 4.5rem;
    border-radius: 0.325rem;
    border-width: 1px;
    border-color: rgba(var(--bs-secondary-rgb), 0.325);
    border-style: solid;
    width: max-content;
    z-index: 1;
    display: none;
}

.ql-toolbar .ql-header.ql-picker.ql-expanded .ql-picker-options {
    display: flex;
}

.ql-toolbar .ql-picker.ql-header {
    line-height: 1.5rem;
    outline: none;
    width: auto;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.ql-toolbar .ql-picker-options .ql-picker-item {
    padding: 0.25rem 1rem;
    cursor: pointer;
    line-height: normal;
    text-align: left;
}

.ql-toolbar .ql-picker-options .ql-picker-item:hover {
    background-color: rgba(var(--bs-primary-rgb), 1);
    color: var(--bs-white);
}

/* FORMATTING */
.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-align-justify {
    text-align: justify;
}

.ql-editor code {
    background-color: rebeccapurple;
    color: white;
    border-radius: 0.25rem;
    padding: 0.125rem 0.5rem;
    font-family: monospace;
}

.ql-editor pre.ql-syntax {
    margin: 0;
    padding: 0.5rem;
    border-radius: 0.325rem;
    background-color: darkslategray;
    color: white;
}

.ql-editor blockquote {
    border-left: solid 0.25rem var(--bs-secondary);
    padding-left: 0.5rem;
    line-height: 2rem;
    margin: 0.5rem 0;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
    min-height: 1.75rem;
}


/* EMOJI */
.ql-emoji {
    width: 22px;
    height: 22px;
    vertical-align: middle;
    cursor: default;
    position: relative;
    top: -1px;
}
.ql-emoji.medium {
    width: 1.5rem;
    height: 1.5rem;
}
.ql-emoji.large {
    width: 2rem;
    height: 2rem;
}
.ql-emoji.larger {
    width: 4rem;
    height: 4rem;
}

.ql-emoji-shortcode.query-match {
    background: rgba(var(--bs-warning-rgb), 0.125);
    color: var(--bs-warning);
}

img.ql-emoji-image,
.ql-emoji img {
    height: 22px;
}

@media screen\0 {
    .ql-emoji {
        pointer-events: none;
    }
}

.tolaria-wysiwyg .emoji-typehead {
    position: absolute;
    background: var(--bg-body);
}

.tolaria-wysiwyg .emoji-typehead ul {
    list-style: none;
    max-height: 20rem;
    overflow: hidden auto;
    margin: 0;
    padding: 0;
}

.tolaria-wysiwyg .emoji-typehead .emoji-typehead-item {
    background: none;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 300;
    padding: 0.325rem 1rem;
    margin: 0;
}

.tolaria-wysiwyg .emoji-typehead .emoji-typehead-item.active {
    background-color: rgba(var(--bs-primary-rgb), 0.325);
    color: var(--bs-white);
}

.tolaria-wysiwyg .emoji-typehead .emoji-typehead-item-inner {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.only-emojis .ql-emoji,
.only-emojis .ql-emoji img {
    width: 2rem;
    height: 2rem;
}

.tolaria-wysiwyg .ql-clipboard {
    display: none;
}

/* MENTION */
.ql-mention-list-container {
    background-color: var(--main-bg);
    border-radius: 0.325rem;
    overflow: hidden auto;
    max-height: 20rem;
    z-index: 20;
    /* height: 20rem; */
}

.ql-mention-list-container ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.ql-mention-list-item {
    padding: 0.5rem 1rem;
}

.ql-mention-list-item.selected {
    background-color: var(--bs-primary);
}

.ql-tolaria-mention .not-current-player {
    color: var(--bs-primary);
    background-color: rgb(var(--bs-primary-rgb), 0.125);
    border-radius: 0.25rem;
    padding: 0 0.25rem;
}

.ql-tolaria-mention .current-player {
    color: var(--bs-warning);
    background-color: rgb(var(--bs-warning-rgb), 0.25);
    border-radius: 0.25rem;
    padding: 0 0.25rem;
}




/* VARIOUS */
.keyboard-key {
    background-color: #4d4d4d;
    color: white;
    padding: 0.25rem 0.325rem;
    font-family: monospace;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    font-size: 0.825rem;
    line-height: 0.825rem;
    font-weight: 400;
}

.keyboard-key.command-key {
    font-size: 1.125rem;
}

.btn-delete-image {
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--bs-gray-600);
    border-radius: 1.5rem;
    color: black;
    border: 2px solid black;
    visibility: hidden;
    cursor: pointer;
}

.message-image:hover .btn-delete-image {
    visibility: visible;
}

.btn-delete-image:hover {
    background-color: var(--bs-gray-500);
}

/* DECK VIEWER */
.tolaria-wysiwyg-deck-viewer-card-tooltip .tooltip-inner {
    padding: 0;
    border-radius: 0.5rem;
    overflow: hidden;
    opacity: 1;
}

.tolaria-wysiwyg-deck-viewer-card-tooltip {
    opacity: 1 !important;
}

.tolaria-wysiwyg-deck-viewer-card-tooltip .tooltip-arrow {
    display: none;
}

/* QUILL VIEWER */
.ngx-quill-view .ql-editor {
    padding: 0;
}

.ngx-quill-view .ql-clipboard {
    display: none;
}

/* Emoji Picker */
.tolaria-emoji-picker-popover {
    border: none;
}
.tooltip-reaction {
    opacity: 1 !important;
}
