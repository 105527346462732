.btn.btn-sm {
  line-height: 1.5rem;
}

.toast:not(:last-child) {
  margin-bottom: 0rem;
}

.toast {
  flex-basis: auto;
}

.modal-body img {
  width: 100%;
  object-fit: contain;
  height: auto;
}

/* Cards */
.card-header {
  /* background-color: var(--card-header-bg); */
  background-color: var(--content-bg);
  border-bottom: 1px solid;
  border-color: rgba(var(--bs-secondary-rgb), 0.325) !important;
}

.card-body {
  min-height: fit-content;
}

hr {
  border-top: var(--hr-border-top);
}

/* Custom Switch */
/* .custom-switch .form-check-label::after {
  top: calc(.1rem + 2px);
}
.custom-switch .form-check-label::before {
  top: .1rem;
} */
.custom-switch .form-check-input:disabled:checked~.form-check-label::before {
  background-color: var(--bg-orange);
}

/* NgbDropDown */
.drop-down-panel {
  background-color: var(--content-inner-border);
  color: var(--content-text);
  padding: 0.5rem 0rem;
  margin-top: 0.25rem;
  overflow: hidden;
  overflow-y: auto;
}

.drop-down-item {
  color: var(--content-text);
  padding: 0.25rem 1.25rem;
}

.drop-down-item:hover,
.drop-down-item:focus {
  color: var(--content-hover-title-text) !important;
  background-color: var(--main-bg) !important;
  cursor: pointer !important;
}

.drop-down-panel::-webkit-scrollbar-track {
  width: 6px;
  border-radius: 6px;
  background: var(--content-inner-border);
}

.drop-down-panel::-webkit-scrollbar {
  width: 6px;
}

.drop-down-panel::-webkit-scrollbar-thumb {
  border: 1.5px solid var(--content-inner-border);
}

.drop-down-panel:hover::-webkit-scrollbar-thumb {
  border: 1.5px solid var(--main-bg);
  background: var(--top-bar-hover-text);
}

.drop-down-panel:hover::-webkit-scrollbar-track {
  background: var(--main-bg);
}

.dropdown-menu {
  background-color: var(--main-bg);
  color: var(--content-text);
}

.dropdown-item {
  color: var(--content-text);
}

.dropdown-menu .separator {
  border-color: var(--content-text);
  opacity: 0.125;
}

.dropdown-item:focus {
  color: var(--content-inner-border);
  background-color: var(--bg-orange);
}

.dropdown-item:hover {
  color: var(--bg-orange);
  background-color: var(--content-inner-border);
}

.dropdown-divider {
  border-top: 1px solid var(--dropdown-divider-bg);
}

/* Modals */
.modal-window-xlarge .modal-dialog {
  min-width: 80vw;
  max-width: 96vw;
}

.modal-body {
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 12rem);
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
  max-height: none;
}

.modal-body.scrollable {
  overflow-y: auto;
}

/* .modal-body::-webkit-scrollbar-track {
  width: 6px;
  border-radius: 6px;
}
.modal-body::-webkit-scrollbar {
  width: 6px;
}
.modal-body::-webkit-scrollbar-thumb {
  border: 1.5px solid var(--main-bg);
}
.modal-body:hover::-webkit-scrollbar-thumb {
  background: var(--top-bar-hover-text);
} */

.modal-deck-photo-uploader .modal-dialog,
.modal-deck-list-registration .modal-dialog {
  max-width: 96vw !important;
  min-width: 96vw !important;
}

.modal-full-size .modal-dialog {
  min-width: 80vw;
  max-width: 96vw;
}

.modal-full-size .modal-dialog .card-body.full-size {
  min-height: calc(100vh - 12rem);
  max-height: calc(100vh - 12rem);
}

/* .modal.playoff-config .modal-content {
  max-height: 90vh;
} */
.playoff-config .card {
  height: 90vh;
  min-width: 100%;
}

.who-starts-match .modal-content {
  background: none !important;
}

.modal-goldfish .modal-dialog {
  max-width: 96vw !important;
  min-width: 96vw !important;
}

/* NgbPopover */
.bs-popover-top>.arrow::after {
  border-top-color: var(--content-inner-border) !important;
}

.bs-popover-left>.arrow::after {
  border-left-color: var(--content-inner-border) !important;
}

.bs-popover-right>.arrow::after {
  border-right-color: var(--content-inner-border) !important;
}

.bs-popover-bottom>.arrow::after {
  border-bottom-color: var(--content-inner-border) !important;
}

.modal-goldfish .modal-dialog {
  max-width: 96vw !important;
  min-width: 96vw !important;
}

.popover {
  background-color: var(--content-inner-border) !important;
  color: var(--content-text) !important;
}

.modal-goldfish .modal-dialog {
  max-width: 96vw !important;
  min-width: 96vw !important;
}

.popover-no-arrow .popover-arrow {
  display: none;
}

.popover-no-padding .popover-body {
  padding: 0;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: var(--font-large);
  background-color: var(--content-inner-border) !important;
  color: var(--content-text) !important;
  border-bottom: 1px solid var(--top-bar-border);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-body {
  color: var(--content-text) !important;
}

.popover-header::before {
  border-bottom-color: rgba(0, 0, 0, 0) !important;
}

.popover.popover-availablity {
  min-width: fit-content !important;
  max-width: fit-content !important;
}

/* .modal-fullscreen .modal-body {
  max-height: calc(100vh - 7.5rem);
} */

/* ng-bootstrap popover overrides */

.tournament-info-popover {
  background: none !important;
  border: none !important;
}

.tournament-info-popover .popover-body {
  padding: 0 !important;
  width: 24rem !important;
}

.tournament-info-popover .popover-arrow {
  display: none !important;
}

/* FULL CALENDAR LIST OVERRIDE */
.fc-list {
  border-color: var(--content-inner-border) !important;
}

.fc-list .fc-list-table .fc-list-day th {
  background-color: var(--body-bg) !important;
  border: none !important;
}

.fc-list .fc-list-table .fc-list-event:hover td {
  background-color: var(--transparent-10) !important;
}

.fc-list-day-cushion.fc-cell-shaded {
  background-color: var(--bg-body) !important;
}

tr.fc-list-event.calendar-item-match .fc-list-event-title {
  flex-grow: 1;
}

.fc-calendar-view .calendar-item-time,
.fc-month-view .calendar-item-time,
.fc-week-view .calendar-item-time {
  display: none !important;
}

.fc-list .fc-list-event-title,
.fc-list .fc-list-event-graphic {
  border: none !important;
}

/* form-control */
.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--bg-muted);
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--bg-muted);
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--bg-muted);
}

.tolaria-card-tooltip {
  opacity: 1 !important;
}

.tolaria-card-tooltip .tooltip-inner {
  padding: 0;
  background: none;
}

.tolaria-card-tooltip .tooltip-arrow {
  display: none;
}

.tolaria-card-tooltip .tooltip-inner img {
  border-radius: 0.65rem;
}

.tolaria-player-popover.popover {
  border: none;
}

.tolaria-player-popover {
  width: 20rem;
  min-width: 20rem;
}

.tolaria-player-popover .popover-arrow {
  display: none;
}

.tolaria-player-popover .popover-body {
  padding: 0;
  margin: 0;
  min-width: 20rem;
}

.tolaria-image-preview-window {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.tolaria-image-preview-dialog.modal-dialog.modal-dialog-centered {
  min-width: calc(100% - 2rem);
  max-width: calc(100% - 2rem);
  max-height: calc(100% - 2rem) !important;
  min-height: calc(100% - 2rem) !important;
  margin: 1rem;
  padding: 0;
}

.tolaria-image-preview-dialog .modal-content {
  border-radius: 0.5rem;
  overflow: hidden;
  min-height: 100% !important;
  max-height: 100% !important;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: var(--bs-dark);
}

.tolaria-image-preview-dialog .carousel-inner,
.tolaria-image-preview-dialog .carousel-item {
  height: 100%;
}

.message-group-people {
  margin-top: 0.5rem !important;
}

.border-opacity-0 {
  --bs-border-opacity: 0;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.modal-md {
  max-width: 675px;
}

.player-search-popup .dropdown-item.active .text-secondary {
  color: var(--body-text) !important;
}

.btn-close.btn-sm {
  width: 0.5rem;
  height: 0.5rem;
}

.btn-close.btn-xs {
  width: 0.325rem;
  height: 0.325rem;
}

.dropdown-toggle.no-arrow:after {
  display: none;
}

.dropdown-item.dropdown-error {
  color: var(--bg-error);
}

.dropdown-item.dropdown-error:hover {
  background-color: var(--bg-error);
  color: var(--bg-white);
}

.popover-pinned-messages {
  border: 1px solid rgba(var(--bs-secondary-rgb), 0.5);
  width: 25rem;
  min-width: 25rem;
}

.popover-pinned-messages .popover-arrow {
  display: none;
}

.popover-noborder {
  border: none;
}
.popover-nopadding,
.popover-nopadding .popover-body {
  padding: 0;
}